



























































import {Component, Prop, Vue, Watch} from 'vue-property-decorator'
import {Coupon, ShortenLink, ShortenLinkEditable} from '~/components/data-class/data-class'
import {createRequest} from '~/utils/network-request'
import BlogSelect from '~/components/select/blog-select.vue'
import CourseSelect from '~/components/select/course-select.vue'
import BlogData from '~/components/blog/blog-data'
import CourseData from '~/components/course/course-data'
import {courseLink, webDomain} from '~/utils/misc'

@Component({
    components: {
        BlogSelect,
        CourseSelect
    }
})
export default class EditLinkDialog extends Vue {
    @Prop({
        default: () => {
            return false
        }
    })
    visible!: boolean

    @Prop({
        default: () => {
            return false
        }
    })
    create: boolean

    @Prop({
        default: () => {
            return new Coupon()
        }
    })
    link: ShortenLink

    org: string
    codeUsed = false

    eLink: ShortenLink = new ShortenLink()

    // generate
    length: number = 5

    selectedBlog = ''
    selectedCourse = ''
    async created() {
        this.linkUpdated()
    }

    @Watch('link', {deep: true})
    linkUpdated() {
        this.link.url = decodeURI(this.link.url)
        this.org = JSON.stringify(this.link)
        this.eLink = JSON.parse(this.org)
    }
    async roll() {
        const res = await createRequest('/shorten-link/code', 'get', {length: this.length}).send()
        this.eLink.code = res.data.code
    }
    async submit() {
        let res
        const body: ShortenLinkEditable = ShortenLinkEditable.from(this.eLink)
        body.url = encodeURI(decodeURI(body.url))

        if (this.create) {
            res = await createRequest('/shorten-link', 'post', {}, body).send()
        } else {
            for (const key of Object.keys(body)) {
                if (body[key] === this.link[key])
                    delete body[key]
            }
            res = await createRequest(`/shorten-link/${this.link._id}`, 'patch', {}, body).send()
        }

        this.$emit('update', res.data.links)
        this.dialogVisible = false
    }
    async checkUsed() {
        this.eLink.code = this.eLink.code.trim()
        const q = {code: this.eLink.code}
        if (!this.create) {
            q['id'] = this.link._id
        }
        const res = await createRequest('/shorten-link/code-used', 'get', q).send()
        this.codeUsed = res.data.used
        if (this.codeUsed) {
            await this.$alert('Code is already taken by other link')
        }
    }

    get changed() {
        return !(JSON.stringify(this.eLink) === this.org)
    }

    get canSubmit() {
        if (this.codeUsed || !this.eLink.url || !this.eLink.code)
            return false
        if (!this.create && !this.changed)
            return false
        return true
    }
    async handleClose(done) {
        if (this.changed) {
            try {
                await this.$confirm('Changes not saved, Exit?')
                done()
            } catch {

            }
        } else {
            done()
        }
    }

    blogSelected() {
        const blog = BlogData.getBlog(this.selectedBlog)
        if (!blog) {
            return
        }

        const path = `blog/${blog._id.replace(/BPID-0*/, '')}-${blog.url_title}/`
        this.eLink.url = webDomain() + path

        this.selectedBlog = ''
    }

    courseSelected() {
        const course = CourseData.getCourse(this.selectedCourse)
        if (!course) {
            return
        }
        this.eLink.url = courseLink(course)

        this.selectedCourse = ''
    }

    set dialogVisible(v) {
        this.$emit('update:visible', v)
    }

    get dialogVisible() {
        return this.visible
    }
}
