import {auth} from '~/plugins/auth'
import {createRequest} from '~/utils/network-request'
import {AdminPermission} from '~/utils/permissions'
import {RouteConfig} from 'vue-router'
import {RouteConfigSingleView} from 'vue-router/types/router'

let permissions

export async function getPermissions(forceUpdate = false) {
    if (!auth.isLoggedIn)
        return []
    if (permissions && !forceUpdate)
        return permissions

    const res = await createRequest(`/member/${auth.memberId}/permissions`, 'GET').send()
    permissions = res.data.permissions
    return permissions
}

export async function checkPermissions(required: string[] | string, forceUpdate = false) {
    if (!required)
        return true

    const p = await getPermissions(forceUpdate)
    if (p.includes(AdminPermission.All))
        return true

    const r = Array.isArray(required) ? required : [required]
    return r.every(r => p.includes(r))
}

export async function checkComponentPermission(route: RouteConfig, forceUpdate = false) {
    const r = route as RouteConfigSingleView
    return await checkPermissions(r.component!!['permission'], forceUpdate)
}
