





































































import {Component, Vue} from 'vue-property-decorator'
import {createRequest} from '~/utils/network-request'
import {ShortenLink} from '~/components/data-class/data-class'
import {ShortenLinkPermission} from '~/utils/permissions'
import EditLinkDialog from './edit-link-dialog.vue'
import copy from 'copy-to-clipboard'
import {checkPermissions} from '~/utils/check-permission'

@Component({
    components: {
        EditLinkDialog
    },
    metaInfo() {
        return {
            title: 'Shorten Link'
        }
    }
})

export default class ShortenLinksManager extends Vue {
    static permission = ShortenLinkPermission.View

    dialogVisible = false
    create = false

    links: ShortenLink[] = []
    editing: ShortenLink = new ShortenLink()

    canCreate = false
    canEdit = false
    async created() {
        this.canCreate = await checkPermissions(ShortenLinkPermission.Create)
        this.canEdit = await checkPermissions(ShortenLinkPermission.Edit)
        await this.getLinks()
    }
    async getLinks() {
        const res = await createRequest('/shorten-links').send()
        this.links = res.data['links']
    }

    linksUpdated(links) {
        this.links = links
    }

    newLink() {
        this.editing = new ShortenLink()
        this.editing.url = 'https://'
        this.create = true
        this.dialogVisible = true
    }

    startEdit(shortenLink) {
        this.editing = shortenLink
        this.create = false
        this.dialogVisible = true
    }

    get urlPrefix() {
        if (location.href.startsWith('https://admin.afterschool.com')) {
            return `https://go.afterschool.com.hk/`
        } else {
            return `https://go-test.afterschool.com.hk/`
        }
    }

    copyCode(code) {
        copy(this.urlPrefix + code, {
            debug: false,
            message: 'Press #{key} to copy',
        })

        this.$message({
            type: 'info',
            message: 'Copied'
        })
    }

    copyUrl(url) {
        copy(url, {
            debug: false,
            message: 'Press #{key} to copy',
        })

        this.$message({
            type: 'info',
            message: 'Copied'
        })
    }
    async deleteLink(shortenLink: ShortenLink) {
        try {
            await this.$confirm('Sure to delete?')
            const res = await createRequest(`/shorten-link/${shortenLink._id}`, 'delete').send()
            this.linksUpdated(res.data.links)
        } catch {

        }
    }
}

