var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-container',[(_vm.canCreate)?_c('el-header',{staticClass:"p-3",staticStyle:{"text-align":"right"}},[_c('el-button',{on:{"click":_vm.newLink}},[_vm._v("New Link")])],1):_vm._e(),_c('el-main',[_c('el-table',{staticClass:"mb-3",staticStyle:{"font-size":"0.8rem"},attrs:{"data":_vm.links,"stripe":"","size":"mini"}},[_c('el-table-column',{attrs:{"label":"#","prop":"_id","align":"center","width":"50"}}),_c('el-table-column',{attrs:{"label":"Access","prop":"access","align":"center","width":"100"}}),_c('el-table-column',{attrs:{"label":"Code","align":"center","width":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.code)+" "),_c('i',{staticClass:"far fa-copy cpy-btn",staticStyle:{"margin-left":"5px"},on:{"click":function($event){return _vm.copyCode(row.code)}}}),_c('a',{attrs:{"href":_vm.urlPrefix + row.code,"target":"_blank"}},[_c('i',{staticClass:"fas fa-external-link-alt cpy-btn",staticStyle:{"margin-left":"5px"},on:{"click":function($event){return _vm.copyCode(row.code)}}})])]}}])}),_c('el-table-column',{attrs:{"label":"URL","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('div',{staticStyle:{"flex":"1","text-align":"left"}},[_vm._v(" "+_vm._s(decodeURI(row.url))+" ")]),_c('i',{staticClass:"far fa-copy cpy-btn",on:{"click":function($event){_vm.copyUrl(decodeURI(row.url))}}})])]}}])}),_c('el-table-column',{attrs:{"label":"Remark","align":"center","width":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.remark)+" ")]}}])}),_c('el-table-column',{attrs:{"label":"Created","align":"center","width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(new Date(row.created).toLocaleDateString('en-GB'))),_c('br'),_vm._v(" "+_vm._s(new Date(row.created).toLocaleTimeString('en-GB'))+" ")]}}])}),(_vm.canEdit)?_c('el-table-column',{attrs:{"label":"Actions","align":"center","width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{attrs:{"size":"mini"},on:{"click":function($event){return _vm.startEdit(row)}}},[_c('i',{staticClass:"el-icon-edit"})]),_c('el-button',{attrs:{"size":"mini"},on:{"click":function($event){return _vm.deleteLink(row)}}},[_c('i',{staticClass:"fas fa-trash"})])]}}],null,false,160736875)}):_vm._e()],1)],1),_c('el-footer'),_c('edit-link-dialog',{attrs:{"visible":_vm.dialogVisible,"link":_vm.editing,"create":_vm.create,"center":""},on:{"update:visible":function($event){_vm.dialogVisible=$event},"update":_vm.linksUpdated}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }